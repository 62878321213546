
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');

body {
  font-family: 'Raleway', sans-serif;
}

h1, p {
  font-family: 'Raleway', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.animate-scroll {
  animation: scroll 40s linear infinite;
}

.about-section {
  position: relative;
  padding: 120px 0 70px;
}

.image-column .inner-column {
  position: relative;
  padding-left: 80px;
}

.image-column .inner-column:before {
  content: '';
  position: absolute;
  width: calc(50% + 80px);
  height: calc(100% + 160px);
  top: -80px;
  left: -3px;
  background: transparent;
  z-index: 0;
  border: 44px solid #00aeef;
}

.image-column .image-1 img {
  box-shadow: 0 30px 50px rgba(8, 13, 62, .15);
  border-radius: 46px;
}

.author-desc {
  padding: 10px 15px;
  width: calc(100% - 152px);
  border-radius: 50px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 1s ease-out forwards;
}
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-up {
  animation: slideUp 1s ease-out forwards;
}

.logo-animation {
  animation: bounce 1.5s infinite; /* Animation lasts 1.5 seconds and repeats infinitely */
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px); /* Moves up 20 pixels */
  }
  60% {
    transform: translateY(-10px); /* Moves up 10 pixels */
  }
}

@keyframes slide {
  0% {
    transform: translateX(100px);
  }
  50% {
    transform: translateX(0);
  }
}

.animate-slide {
  animation: slide 9s ease-in-out infinite;
}

/* @keyframes fadeInLeft {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
} */

/* Fade-in-right animation */
/* @keyframes fadeInRight {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
} */

/* Utility classes to trigger the animations */
/* .fade-in-left {
  animation: fadeInLeft 1s ease-out forwards;
}

.fade-in-right {
  animation: fadeInRight 1s ease-out forwards;
}

.opacity-0 {
  opacity: 0;
} */


/* About section */
@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-left {
  opacity: 0;
  animation: fadeLeft 0.8s forwards;
}

/* product section */
@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-right-animation {
  animation: fadeRight 1s ease-out forwards;
}

.opacity-0 {
  opacity: 0;
}

/* service section */
@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-left {
  animation: fadeLeft 0.5s ease forwards;
}

/* technology section */
/* styles.css */
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-right {
  animation: fadeInRight 0.5s ease forwards;
}

/* partner section */
@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-left {
  animation: fadeLeft 0.5s ease forwards;
}
